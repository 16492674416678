<!-- 营收统计 -->
<template>
    <div class="a-mt-60">
        <div class="a-flex-rsbc a-pt-25">
            <div class="a-flex-rfsfe">
                <span class="a-c-master a-fw-700 a-fs-16">营收统计</span>
                <span class="a-c-normal a-fs-14 a-ml-10">(单位: 元)</span>
            </div>
        </div>
        <div class="a-flex-rfsc a-mt-25">
            <div class="a-flex-cfsfs a-flex-1" >
                <span class="a-fs-14 a-c-normal">历史总收益（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics&&revenueStatistics.incomeHis?revenueStatistics.incomeHis:0) | initMoney }}</span>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1" >
                <span class="a-fs-14 a-c-normal">{{ infoType == 'business' ? '商户' : '' }}历史单插槽收益（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics&&revenueStatistics.incomeDeviceHisAvg?revenueStatistics.incomeDeviceHisAvg:0) | initMoney }}</span>
                    <span class="a-fs-14 a-c-normal">/插槽/天</span>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1"  >
                <span class="a-fs-14 a-c-normal">历史平均营收（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics&&revenueStatistics.incomeDayHisAvg?revenueStatistics.incomeDayHisAvg:0) | initMoney }}</span>
                    <span class="a-fs-14 a-c-normal">/天</span>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1">
                <span class="a-fs-14 a-c-normal">插槽历史使用率</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics&&revenueStatistics.deviceUsingRate?revenueStatistics.deviceUsingRate:0) }}%</span>
                </div>
            </div>
        </div>
        <!-- 30天营收统计 -->
        <div class="a-flex-rfsc a-mt-45">
            <div class="a-flex-cfsfs a-flex-1" >
                <span class="a-fs-14 a-c-normal">30天总收益（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics30&&revenueStatistics30.incomeHis?revenueStatistics30.incomeHis:0) | initMoney }}</span>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1" >
                <span class="a-fs-14 a-c-normal">{{ infoType == 'business' ? '商户' : '' }}30天单插槽收益（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics30&&revenueStatistics30.incomeDeviceHisAvg?revenueStatistics30.incomeDeviceHisAvg:0) | initMoney }}</span>
                    <span class="a-fs-14 a-c-normal">/插槽/天</span>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1"  >
                <span class="a-fs-14 a-c-normal">30天平均营收（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics30&&revenueStatistics30.incomeDayHisAvg?revenueStatistics30.incomeDayHisAvg:0) | initMoney }}</span>
                    <span class="a-fs-14 a-c-normal">/天</span>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1">
                <span class="a-fs-14 a-c-normal">30天插槽使用率</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics30&&revenueStatistics30.deviceUsingRate?revenueStatistics30.deviceUsingRate:0) }}%</span>
                </div>
            </div>
        </div>
        <!-- 7天营收统计 -->
        <div class="a-flex-rfsc a-mt-45">
            <div class="a-flex-cfsfs a-flex-1" >
                <span class="a-fs-14 a-c-normal">7天总收益（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics7&&revenueStatistics7.incomeHis?revenueStatistics7.incomeHis:0) | initMoney }}</span>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1" >
                <span class="a-fs-14 a-c-normal">{{ infoType == 'business' ? '商户' : '' }}7天单插槽收益（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics7&&revenueStatistics7.incomeDeviceHisAvg?revenueStatistics7.incomeDeviceHisAvg:0) | initMoney }}</span>
                    <span class="a-fs-14 a-c-normal">/插槽/天</span>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1"  >
                <span class="a-fs-14 a-c-normal">7天平均营收（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics7&&revenueStatistics7.incomeDayHisAvg?revenueStatistics7.incomeDayHisAvg:0) | initMoney }}</span>
                    <span class="a-fs-14 a-c-normal">/天</span>
                </div>
            </div>
            <div class="a-flex-cfsfs  a-flex-1">
                <span class="a-fs-14 a-c-normal">7天插槽使用率</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics7&&revenueStatistics7.deviceUsingRate?revenueStatistics7.deviceUsingRate:0) }}%</span>
                </div>
            </div>
        </div>
        <!-- 今日营收统计 -->
        <div class="a-flex-rfsfs a-mt-45">
            <div class="a-flex-cfsfs a-flex-1" >
                <span class="a-fs-14 a-c-normal">今日总收益（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics1&&revenueStatistics1.incomeHis?revenueStatistics1.incomeHis:0) | initMoney }}</span>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1" >
                <span class="a-fs-14 a-c-normal">{{ infoType == 'business' ? '商户' : '' }}今日单插槽收益（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics1&&revenueStatistics1.incomeDeviceHisAvg?revenueStatistics1.incomeDeviceHisAvg:0) | initMoney }}</span>
                    <span class="a-fs-14 a-c-normal">/插槽/天</span>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1">
                <span class="a-fs-14 a-c-normal">{{ infoType == 'city' || infoType == 'province' ? '昨日' : '今日' }}营业额/退款（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin a-flex-wrap" style="width: 240px">
                    <div class="a-flex-rfsbl">
                        <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                        <span class="a-fs-32 a-c-master a-fw-700">{{ (pageData&&pageData.timeTransaction?pageData.timeTransaction:0) | initMoney }} / </span>
                    </div>
                    <div class="a-flex-rfsbl">
                        <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                        <span class="a-fs-32 a-c-master a-fw-700">{{ (pageData&&pageData.timeRefundAmount?pageData.timeRefundAmount:0) | initMoney }}</span>
                    </div>
                </div>
            </div>
            <div class="a-flex-cfsfs a-flex-1">
                <span class="a-fs-14 a-c-normal">今日插槽使用率</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-32 a-c-master a-fw-700">{{ (revenueStatistics1&&revenueStatistics1.deviceUsingRate?revenueStatistics1.deviceUsingRate:0) }}%</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                revenueStatistics: '',
                revenueStatistics30: '',
                revenueStatistics7: '',
                revenueStatistics1: '',
                pageData: ''
            }
        },
        props: ['id','params','infoType'],
        watch:{
            params:{
                handler (val) {
                    this.getRevenueStatisticsInfo()
                    this.getRevenueStatisticsInfo(30)
                    this.getRevenueStatisticsInfo(7)
                    this.getRevenueStatisticsInfo(1)
                    this.getData()
                }
            }
        },
        filters:{
            initMoney (val) {
                var num = Number(val);
                if(!num){//等于0
                    return '0.00';
                }else{//不等于0
                    num = Math.round((num)*100)/10000;
                    num = num.toFixed(2);
                    num+='';//转成字符串
                    var reg=num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
                // console.log(num.indexOf('.')>-1)
                    return num.replace(reg, '$1,')//千分位格式化
                }
            }
        },
        methods:{
            getRevenueStatisticsInfo (type) {
                let apiUrl, dateRange
                if(this.infoType == 'business'){
                    apiUrl = this.$Config.apiUrl.getAnalysisRevenueBusiness
                }else if(this.infoType == 'income'){
                    apiUrl = this.$Config.apiUrl.getAnalysisRevenueIncome
                }else if(this.infoType == 'station'){
                    apiUrl = this.$Config.apiUrl.getAnalysisRevenueStation
                }else if(this.infoType == 'province'){
                    apiUrl = this.$Config.apiUrl.getAnalysisRevenueCity
                }else if(this.infoType == 'city'){
                    apiUrl = this.$Config.apiUrl.getAnalysisRevenueCity
                }
                // if(type == 30) {
                //     if(this.infoType == 'city' || this.infoType == 'province'){
                //         dateRange = [this.$getDay.getTodayBeforeDays(30), this.$getDay.getTodayBeforeDays(1)]
                //     }else{
                //         dateRange = [this.$getDay.getTodayBeforeDays(29), this.$getDay.getTodayBeforeDays(0)]
                //     }
                // }else if (type == 7) {
                //     if(this.infoType == 'city' || this.infoType == 'province'){
                //         dateRange = [this.$getDay.getTodayBeforeDays(7), this.$getDay.getTodayBeforeDays(1)]
                //     }else{
                //         dateRange = [this.$getDay.getTodayBeforeDays(6), this.$getDay.getTodayBeforeDays(0)]
                //     }
                // }else if (type == 1) {
                //     if(this.infoType == 'city' || this.infoType == 'province'){
                //         dateRange = [this.$getDay.getTodayBeforeDays(1), this.$getDay.getTodayBeforeDays(1)]
                //     }else{
                //         dateRange = [this.$getDay.getTodayBeforeDays(0), this.$getDay.getTodayBeforeDays(0)]
                //     }
                // }else {
                //     dateRange = ['','']
                // }
                if(type == 30) {
                    dateRange = [this.$getDay.getTodayBeforeDays(30), this.$getDay.getTodayBeforeDays(1)]
                }else if (type == 7) {
                    dateRange = [this.$getDay.getTodayBeforeDays(7), this.$getDay.getTodayBeforeDays(1)]
                }else if (type == 1) {
                    if(this.infoType == 'city' || this.infoType == 'province'){
                        dateRange = [this.$getDay.getTodayBeforeDays(1), this.$getDay.getTodayBeforeDays(1)]
                    }else{
                        dateRange = [this.$getDay.getTodayBeforeDays(0), this.$getDay.getTodayBeforeDays(0)]
                    }
                }else {
                    dateRange = ['','']
                }
                this.$Axios._post({
                    url: apiUrl,
                    method: "post",
                    showLoading: false,
                    params: {
                        ...this.params,
                        startDay: dateRange[0],
                        endDay: dateRange[1]
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        if(type == 30){
                            this.revenueStatistics30 = res.result.data
                        }else if (type == 7) {
                            this.revenueStatistics7 = res.result.data
                        }else if (type == 1) {
                            this.revenueStatistics1 = res.result.data
                        }else{
                            this.revenueStatistics = res.result.data
                        }
                        
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            },
            getData () {
				let apiUrl
                if(this.infoType == 'business'){
                    apiUrl = this.$Config.apiUrl.getAnalysisBusinessPage
                }else if(this.infoType == 'income'){
                    apiUrl = this.$Config.apiUrl.getAnalysisIncomePage
                }else if(this.infoType == 'station'){
                    apiUrl = this.$Config.apiUrl.getAnalysisStationPage
                }else if(this.infoType == 'province'){
                    apiUrl = this.$Config.apiUrl.getAnalysisCityPage
                }else if(this.infoType == 'city'){
                    apiUrl = this.$Config.apiUrl.getAnalysisCityPage
                }
                let dateRange = []
                if(this.infoType == 'city' || this.infoType == 'province'){
                    dateRange = [this.$getDay.getTodayBeforeDays(1), this.$getDay.getTodayBeforeDays(1)]
                }else{
                    dateRange = [this.$getDay.getTodayBeforeDays(0), this.$getDay.getTodayBeforeDays(0)]
                }
                this.$Axios._post({
                    url: apiUrl,
                    method: "post",
                    showLoading: false,
                    params: {
                        ...this.params,
                        startDay: dateRange[0],
                        endDay: dateRange[1],
                        searchEndTime: dateRange[1],
                        searchStartTime: dateRange[0],
                        pageNum: 1,
						pageSize: 10,
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.pageData = res.result.data.list[0]
                        console.log(this.pageData,1);
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
			},
        }
    }
</script>

<style lang="scss" scoped>
.border-lt{
    border-left: 1px solid #E3E3E3;
    border-top: 1px solid #E3E3E3;
}
.border-rt{
    border-right: 1px solid #E3E3E3;
    border-top: 1px solid #E3E3E3;
}
.border-lb{
    border-left: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
}
.border-rb{
    border-right: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    
}
.border-r{
    border-right: 1px solid #E3E3E3;
}
</style>