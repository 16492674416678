<!-- 充电时长分布 -->
<template>
    <div class="a-mt-60">
        <div class="a-flex-rsbc a-pt-25">
            <div class="a-flex-rfsfe">
                <span class="a-c-master a-fw-700 a-fs-16">充电时长分布</span>
            </div>
            <div class="a-flex-rfsc">
                <div class="tips "></div>
                <span class="a-c-normal a-fs-14 a-ml-11">订单数</span>
            </div>
        </div>
        <div id="mychartCharg" class="mychart a-mt-32"></div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                params_req: ''
            }
        },
        mounted () {
        },
        props: ['id','params','infoType'],
        watch:{
            params:{
                handler (val) {
                    this.params_req = JSON.parse(JSON.stringify(val))
                    this.getChartData()
                }
            }
        },
        methods: {
            drawChart (xAxis=[],data=[]) {
                var chartDom = document.getElementById('mychartCharg');
                var option;

                option = {
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '36px',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        axisLine: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        splitLine:{
                            show:false
                        },
                        axisLabel:{
                            color: '#999999',
                            showMaxLabel: true,
                            formatter: function (value, index) {
                                return value.replace('h','小时')
                            }
                        },
                        data: xAxis
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel:{
                            color: '#999999',
                            showMaxLabel: true,
                            formatter: function (value, index) {
                                if(value>10000){
                                    return Number((value / 10000).toFixed(2)) + '万'
                                }else if(value>100000000){
                                    return Number((value / 100000000).toFixed(2)) + '亿'
                                }else{
                                    return value
                                }
                            }
                        },
                        splitLine:{
                            lineStyle:{
                                color: '#EEEEEE',
                                type: 'dashed'
                            }
                        },
                    },
                    series: [
                        {
                            data: data,
                            type: 'bar',
                            barWidth: 19,
                            label: {
                                normal:{
                                    show:true,
                                    position: 'top',
                                    textStyle:{
                                        color: '#606366',
                                    }
                                    
                                }
                            },
                            itemStyle: {
                                normal:{
                                    color: '#6699FF',
                                    barBorderRadius:[4,4,0,0],
                                }
                            },
                        }
                    ],
                };
                var myChart = this.$echarts.init(chartDom);
                
                myChart.setOption(option,true);
            },
            getChartData () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getStatOrderTimeDistriList,
                    method: "post",
                    showLoading: false,
                    params: this.params_req
                }).then(res => {
                    if(res.result.code == 0){
                        let real = JSON.parse(res.result.data).real 
                        delete real.rhother
                        let datas = Object.values(real).reverse()
                        let xAxis = Object.keys(real).reverse()
                        this.drawChart(xAxis,datas)
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.tips{
    width: 11px;
    height: 11px;
    background: #6699FF;
    border-radius: 3px;
}
.mychart{
    width: 100%;
    height: 300px;
}
</style>