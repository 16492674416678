<!-- 站点-设备情况 -->
<template>
    <div class="a-mt-60">
        <div class="a-flex-rsbc a-pt-25">
            <div class="a-flex-rfsfe">
                <span class="a-c-master a-fw-700 a-fs-16">设备情况</span>
            </div>
        </div>
        <div class="a-mt-32 border-box-dev a-flex-rfsc a-flex-wrap">
            <div class="a-flex-cfsfs" style="margin-right: 200px;">
                <span class="a-fs-14 a-c-normal">设备总数</span>
                <div class="a-flex-rfsb a-mt-10">
                    <span class="a-fs-24 a-c-master a-fw-700">{{ (devInfo&&devInfo.deivceNum?devInfo.deivceNum:0) }}</span>
                </div>
            </div>

            <div class="a-flex-cfsfs" style="margin-right: 200px;">
                <span class="a-fs-14 a-c-normal">在线设备数</span>
                <div class="a-flex-rfsb a-mt-10">
                    <span class="a-fs-24 a-c-master a-fw-700">{{ (devInfo&&devInfo.deviceOnline?devInfo.deviceOnline:0) }}</span>
                </div>
            </div>

            <div class="a-flex-cfsfs" >
                <span class="a-fs-14 a-c-normal">使用中插槽数</span>
                <div class="a-flex-rfsb a-mt-10">
                    <span class="a-fs-24 a-c-master a-fw-700">{{ (devInfo&&devInfo.deviceUse?devInfo.deviceUse:0) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                devInfo: ''
            }
        },
        props:['id','params','infoType'],
        watch:{
            params:{
                immediate: true,
                handler (val) {
                    this.getStationDev()
                }
            }
        },
        methods:{
            getStationDev () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getAnalysisDevInfo,
                    method: "get",
                    showLoading: false,
                    params: {
                        ...this.params,
                    }
                }).then(res => {
                    if(res.result.code == 0){                        
                        this.devInfo = res.result.data
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.border-box-dev{
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #E3E3E3;
    padding: 32px 43px;
}
</style>