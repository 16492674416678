<template>
    <div class="a-mt-60">
        <div class="a-flex-rsbc">
            <div class="a-flex-rfsfe">
                <span class="a-c-master a-fw-700 a-fs-16">车库收益分布</span>
            </div>
            <jd-other-date-range
                ref="dateRange"
                @change="dateChange"
                :pickerOptions="pickerOptions" 
                :timeType.sync="timeType"
                valueFormat="yyyy-MM-dd HH:mm:ss"
                :minDate.sync="startTime" 
                :maxDate.sync="endTime">
            </jd-other-date-range>
        </div>
        <div class="">
            <div class="a-flex-rfsc a-flex-1 a-flex-wrap">
                <div ref="trendChart" style="height:280px;" class="a-w-50 a-mtb-20"></div>
                <div ref="trendChartAmount" style="height:280px;" class="a-w-50 a-mtb-20"></div>
                <div ref="trendChartMonth" style="height:280px;" class="a-w-50 a-mtb-20"></div>
            </div>
            <div class="border-box-dev a-flex-rfsfs a-flex-wrap a-flex-1">
                <div class="a-flex-cfsfs  border-box-dev-item" >
                    <span class="a-fs-14 a-c-normal">车库历史总收益</span>
                    <div class="a-flex-rfsb a-mt-10">
                        <span class="a-fs-20 a-c-master">￥</span>
                        <span class="a-fs-24 a-c-master a-fw-700">{{ util.numFormat(orderIncomeInfo.hisIncomeAmount || 0) }}</span>
                    </div>
                </div>

                <div class="a-flex-cfsfs  border-box-dev-item" >
                    <span class="a-fs-14 a-c-normal">车库时间范围内收益</span>
                    <div class="a-flex-rfsb a-mt-10">
                        <span class="a-fs-20 a-c-master">￥</span>
                        <span class="a-fs-24 a-c-master a-fw-700">
                            {{ util.numFormat(orderIncomeInfo.timeIncomeAmount || 0) }}
                        </span>
                    </div>
                </div>

                <div class="a-flex-cfsfs  border-box-dev-item" >
                    <span class="a-fs-14 a-c-normal">车库时间范围内交易金额</span>
                    <div class="a-flex-rfsb a-mt-10">
                        <span class="a-fs-20 a-c-master">￥</span>
                        <span class="a-fs-24 a-c-master a-fw-700">
                            {{ util.numFormat(orderIncomeInfo.transactionAmount || 0) }}
                        </span>
                    </div>
                </div>

                <div class="a-flex-cfsfs border-box-dev-item">
                    <span class="a-fs-14 a-c-normal">车库当前车辆均价</span>
                    <div class="a-flex-rfsb a-mt-10">
                        <span class="a-fs-20 a-c-master">￥</span>
                        <span class="a-fs-24 a-c-master a-fw-700">
                            {{ util.numFormat(orderIncomeInfo.bikePrice || 0) }}
                        </span>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import util from '../../../utils/util.js'
export default {
    data() {
        return {
            util: util,
            choiceDate: '',
            choiceMonth: '',
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    this.choiceMonth = minDate
                    if (maxDate) {
                        this.choiceDate = ''
                        this.choiceMonth = ''
                    }
                },
                disabledDate: (time) => {
                    if (this.choiceDate) {
                        if(this.timeType == 0){
                            let one = 31 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else if(this.timeType == 1){
                            let minMonth = this.choiceMonth.getMonth(),
                            lastYear = new Date(this.choiceMonth).setMonth(minMonth - 11),
                            nextYear = new Date(this.choiceMonth).setMonth(minMonth + 11);
                            return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf();
                        }
                    }else{
                        return ''
                    }
                },
            },
            timeType: 0,
            startTime: '',
            endTime: '',
            orderIncomeInfo: '',
            params_req: '',
            data: [
                { value: 0, name: '其他', showVal: 0 }
            ],
            isTempcardDic: [],//套餐卡类型字典
            option: {
                title: {
                    text: '',
                    left: 'center',
                    top: 'bottom',
                    textStyle: {
                        fontWeight: 'normal',
                        fontSize: 14
                    }
                },
                color: ['#3BA1FF', '#4DCB73', '#F56C6C', '#FF9C00', '#FFD700','#3ba272'],
                tooltip: {
                    trigger: 'item',
                    formatter: (item) =>{
                        let htmlContxt = `<div class="a-flex-cfsfs" style='background:#FFFFFF;min-width:100px'>
                                <span style='font-size: 14px;color: #606366;'>`+ item.data.name +`</span>`
                            +`<span style='font-size: 17px;font-weight: 700;color: #303133;padding:10px 0 0'>`+ item.data.showVal +`</span>`
                            return htmlContxt
                    }
                },
                series: [
                    {
                        name: '订单收益',
                        type: 'pie',
                        radius: ['0', '65%'],
                        center: ['50%', '45%'],
                        minShowLabelAngle: 0,
                        itemStyle: {
                            borderRadius: 4,
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        label: {
                            normal: {
                                formatter: function (params) {
                                    return params.data.name + ' ' + params.data.showVal
                                }
                            }
                        },
                        labelLine:{
                            normal: {
                                showAbove: true,
                            }
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '12',
                                fontWeight: 'bold'
                            }
                        },
                        data: []
                    }
                ]
            }
        }
    },
    props: ['id','params','infoType'],
    watch:{
        params:{
            immediate: true,
            handler (val) {
                this.params_req = JSON.parse(JSON.stringify(val))
                this.$nextTick(()=>{
                    if(this.infoType == 'province' || this.infoType == 'city'){
                        this.startTime = this.$getDay.getTodayBeforeDays(1) + ' 00:00:00'
                        this.endTime = this.$getDay.getTodayBeforeDays(1) + ' 23:59:59'
                    }else{
                        this.startTime = this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                        this.endTime = this.$getDay.getTodayBeforeDays(0) + ' 23:59:59'
                    }
                    this.$refs['dateRange'].value1 = [this.startTime, this.endTime]
                    this.getStationIncomeInfo()
                    this.getParkAmountDistri()
                    this.getMonthCardTypeDistr()
                })
            }
        }
    },
    mounted() {
        this.$getDic('istempcard').then(res=>{ this.isTempcardDic = res; })
    },
    methods: {
        dateChange () {
            this.getStationIncomeInfo()
            this.getParkAmountDistri()
            this.getMonthCardTypeDistr()
        },
        // 获取收益、交易额统计
        getStationIncomeInfo () {
            let apiUrl
            if(this.infoType == 'business'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkTjBusiness
            }else if(this.infoType == 'income'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkTjIncome
            }else if(this.infoType == 'station'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkTjStation
            }else if(this.infoType == 'province'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkTjCity
            }else if(this.infoType == 'city'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkTjCity
            }
            this.$Axios._post({
                url: apiUrl,
                method: "post",
                showLoading: false,
                params: {
                    ...this.params_req,
                    startDay: this.startTime,
                    endDay: this.endTime,
                    dateType: this.timeType
                }
            }).then(res => {
                if(res.result.code == 0){                        
                    this.orderIncomeInfo = res.result.data
                }else{
                    this.$message.error(res.result.message)
                }
            })
        },
        // 获取饼状图数据
        getParkAmountDistri () {
            let apiUrl
            if(this.infoType == 'business'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkDistriBusiness
            }else if(this.infoType == 'income'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkDistriIncome
            }else if(this.infoType == 'station'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkDistriStation
            }else if(this.infoType == 'province'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkDistriCity
            }else if(this.infoType == 'city'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkDistriCity
            }
            this.$Axios._post({
                url: apiUrl,
                method: "post",
                showLoading: false,
                params: {
                    ...this.params_req,
                    startDay: this.startTime,
                    endDay: this.endTime,
                    dateType: this.timeType
                }
            }).then(res=>{
                if (res.result.code == 0) {
                    let incomeData = [], transitionData = [], transitionRefound = []
                    res.result.data.map(item=>{
                        // 收益
                        incomeData.push({
                            name: item.name,
                            showVal: item.incomeAmount 
                                    ? Number((item.incomeAmount/100).toFixed(2))
                                    : 0,
                            value: item.incomeAmount 
                                    ? item.incomeAmount >= 0
                                        ? Number((item.incomeAmount/100).toFixed(2)) 
                                        : 0
                                    : 0
                        })

                        // 交易额
                        transitionData.push({
                            name: item.name,
                            showVal: item.transactionAmount 
                                    ? Number((item.transactionAmount/100).toFixed(2))
                                    : 0,
                            value: item.transactionAmount 
                                    ? item.transactionAmount >= 0
                                        ? Number((item.transactionAmount/100).toFixed(2)) 
                                        : 0
                                    : 0
                        })

                        // 退款
                        transitionRefound.push({
                            name: item.name,
                            showVal: item.refundAmount 
                                    ? Number((item.refundAmount/100).toFixed(2))
                                    : 0,
                            value: item.refundAmount 
                                    ? item.refundAmount >= 0
                                        ? Number((item.refundAmount/100).toFixed(2)) 
                                        : 0
                                    : 0
                        })

                    })

                    // 绘制收益图
                    this.drawChartIncome(incomeData&&incomeData.length?incomeData:this.data)

                    // 绘制交易额及退款
                    this.drawChartAmount([transitionData&&transitionData.length?transitionData:this.data, transitionRefound&&transitionRefound.length?transitionRefound:this.data])

                } else {
                    this.$message.error(res.result.msg)
                    this.drawChartIncome(this.data)
                    this.drawChartAmount([this.data,this.data])
                }
            }).catch(err=>{
                this.drawChartIncome(this.data)
                this.drawChartAmount([this.data,this.data])
            })
        },

        // 获取套餐类型统计分布
        getMonthCardTypeDistr () {
            let apiUrl
            if(this.infoType == 'business'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkBusinessMonthCard
            }else if(this.infoType == 'income'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkIncomeMonthCard
            }else if(this.infoType == 'station'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkStationMonthCard
            }else if(this.infoType == 'province'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkCityMonthCard
            }else if(this.infoType == 'city'){
                apiUrl = this.$Config.apiUrl.getAnalysisParkCityMonthCard
            }
            this.$Axios._post({
                url: apiUrl,
                method: "post",
                showLoading: false,
                params: {
                    ...this.params_req,
                    startDay: this.startTime,
                    endDay: this.endTime,
                    dateType: this.timeType
                }
            }).then(res => {
                if(res.result.code == 0){       
                    let result = res.result.data
                    let monthCardType = []
                    let monthCardBikeType = []
                    let monthCardBikeTypeFlat = []
                    result.map(item=>{
                        let monthCard = monthCardType.find(res=>{
                            return res.monthcardTimeType == item.monthcardTimeType
                        })
                        if(!monthCard){
                            // 如果已经push了该类型的套餐卡类型，则不再push
                            monthCardType.push({
                                value: item.monthCardNum || 0,
                                name: this.getMonthCardTypeName(item.monthcardTimeType),
                                monthcardTimeType: item.monthcardTimeType
                            })
                        }
                    })        
                    monthCardType.map((item,index)=>{
                        let arr = result.filter(re=>{
                            return re.monthcardTimeType == item.monthcardTimeType
                        })
                        arr = arr.map(ite=>{
                            return {
                                value: ite.bikeNum || 0,
                                name: this.getMonthCardTypeName(ite.monthcardTimeType) + '-' + (ite.bikeCategroyStr || '') + '-' + (ite.bikeBatteryStr || ''),
                            }
                        })
                        this.$set(monthCardBikeType,index,arr)
                    })
                    monthCardBikeTypeFlat = monthCardBikeType.flat(1)
                    this.drawChartMonthCardType([monthCardType&&monthCardType.length?monthCardType:this.data,
                                                monthCardBikeTypeFlat&&monthCardBikeTypeFlat.length?monthCardBikeTypeFlat:this.data])
                }else{
                    this.$message.error(res.result.message)
                    this.drawChartMonthCardType([this.data,this.data])
                }
            }).catch(err=>{
                console.log(err);
                this.drawChartMonthCardType([this.data,this.data])
            })
        },



        drawChartIncome(data = []) {
            let option = JSON.parse(JSON.stringify(this.option))
            option.title.text = '套餐收益分布（元）'
            option.series[0].data = data
            option.series[0].name = '套餐收益'
            option.series[0].label = {
                normal: {
                    formatter: function (params) {
                        return params.data.name + ' ' + params.data.showVal
                    }
                }
            }
            
            if(!this.chartIncome){
                this.chartIncome = this.$echarts.init(this.$refs['trendChart']);
            }
            this.chartIncome.clear()
            this.chartIncome.setOption(option,true);
        },
        drawChartAmount(data = []) {
            let option = JSON.parse(JSON.stringify(this.option))
            option.title.text = '套餐交易额分布（元）'
            option.series[0].data = data[0]
            option.series[0].name = '套餐交易额'
            option.series[0].radius = ['0%', '40%']
            option.series[0].label = {
                normal: {
                    formatter: function (params) {
                        return params.data.name + ' ' + params.data.showVal
                    }
                }
            }
            option.series[1] = {
                name: '套餐交易额退款',
                type: 'pie',
                radius: ['50%', '65%'],
                center: ['50%', '45%'],
                itemStyle: {
                    borderRadius: 4,
                    borderColor: '#fff',
                    borderWidth: 0
                },
                minShowLabelAngle: 0,
                label: {
                    normal: {
                        formatter: function (params) {
                            return params.data.name + '(退款) ' + params.data.showVal
                        }
                    }
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '12',
                        fontWeight: 'bold'
                    }
                },
                data: data[1]
            }
            if(!this.chartAmount){
                this.chartAmount = this.$echarts.init(this.$refs['trendChartAmount']);
            }
            this.chartAmount.clear()
            this.chartAmount.setOption(option,true);
        },
        drawChartMonthCardType (data = []) {
            let option = JSON.parse(JSON.stringify(this.option))
            option.title.text = '套餐类型统计分布（辆）'
            option.series[0].data = data[0]
            option.series[0].name = '套餐统计'
            option.series[0].radius = ['0%', '40%']
            option.series[0].label = {
                normal: {
                    formatter: function (params) {
                        return params.data.name + ' ' + params.data.value
                    }
                }
            },
            option.series[1] = {
                name: '套餐车辆类型',
                type: 'pie',
                radius: ['50%', '65%'],
                center: ['50%', '45%'],
                itemStyle: {
                    borderRadius: 4,
                    borderColor: '#fff',
                    borderWidth: 0
                },
                minShowLabelAngle: 0,
                label: {
                    normal: {
                        formatter: function (params) {
                            return params.data.name + ' ' + params.data.value
                        }
                    }
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '12',
                        fontWeight: 'bold'
                    }
                },
                data: data[1]
            }
            if(!this.chartMonth){
                this.chartMonth = this.$echarts.init(this.$refs['trendChartMonth']);
            }
            this.chartMonth.clear()
            this.chartMonth.setOption(option,true);
        },
        // 获取套餐卡类型name
        getMonthCardTypeName (code) {
            let obj = this.isTempcardDic.find(res=>{
                return res.value == code
            })
            if(obj){
                return obj.text
            }else{
                return '-'
            }
        }
    },
    
}
</script>
<style lang="scss" scoped>
    .timePicker{
        padding: 0 !important;
    }
    .border-box-dev{
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #E3E3E3;
        min-height: 130px;
        margin-top: 36px;
        &-item{
            margin: 32px 0 0 43px;
            width: calc(25% - 43px);
        }
    }
    .e-title{
        width: 100px;
        padding-right: 16px;
    }
    .a-w-50{
        width: 50%;
    }
</style>