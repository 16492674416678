<!-- 设备离线天数分布 -->
<template>
    <div class="a-mt-60">
        <div class="a-pt-25">
            <div class="a-flex-rsbc">
                <span class="a-c-master a-fw-700 a-fs-16">设备离线天数分布</span>
                <le-date-range 
                    ref="dateRange"
                    :pickerOptions="pickerOptions" 
                    :minDate.sync="queryStartDate" 
                    :maxDate.sync="queryEndDate" 
                    :clearable="false"
                    :defaultTime="['00:00:00','23:59:59']"
                    valueFormat="yyyy-MM-dd HH:mm:ss" />
            </div>
            <div class="a-flex-rfec">
                <div class="tips "></div>
                <span class="a-c-normal a-fs-14 a-ml-11">离线设备数</span>
            </div>
        </div>
        <div id="mychartDevOff" class="mychart a-mt-32"></div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                params_req: '',
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) {
                            this.choiceDate = ''
                        }
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            let one = 31 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
                queryStartDate: '',
                queryEndDate: '',
                defaultXAxis: ['1天','2天','3天','4天','5天','10天','15天','20天','30天'],
                defaultDatas: [0,0,0,0,0,0,0,0,0]
            }
        },
        mounted () {
        },
        props: ['id','params','infoType'],
        watch:{
            params:{
                handler (val) {
                    this.params_req = JSON.parse(JSON.stringify(val))
                    if(this.infoType == 'province' || this.infoType == 'city'){
                        this.queryStartDate = this.$getDay.getTodayBeforeDays(31) + ' 00:00:00'
                        this.queryEndDate = this.$getDay.getTodayBeforeDays(1) + ' 23:59:59'
                    }else{
                        this.queryStartDate = this.$getDay.getTodayBeforeDays(30) + ' 00:00:00'
                        this.queryEndDate = this.$getDay.getTodayBeforeDays(0) + ' 23:59:59'
                    }
                    this.$refs['dateRange'].value1 = [this.queryStartDate, this.queryEndDate]
                    this.getChartData()
                }
            },
            dateStr () {
                this.getChartData()
            }
        },
        computed:{
            dateStr () {
                return this.queryStartDate + this.queryEndDate
            }
        },
        methods: {
            drawChart (xAxis=[],data=[]) {
                var chartDom = document.getElementById('mychartDevOff');
                var option;

                option = {
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '36px',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        axisLine: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        splitLine:{
                            show:false
                        },
                        axisLabel:{
                            color: '#999999',
                            showMaxLabel: true
                        },
                        data: xAxis
                    },
                    yAxis: {
                        type: 'value',
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine:{
                            lineStyle:{
                                color: '#EEEEEE',
                                type: 'dashed'
                            }
                        },
                    },
                    series: [
                        {
                            data: data,
                            type: 'bar',
                            barWidth: 19,
                            label: {
                                normal:{
                                    show:true,
                                    position: 'top',
                                    textStyle:{
                                        color: '#606366',
                                    }
                                    
                                }
                            },
                            itemStyle: {
                                normal:{
                                    color: '#6699FF',
                                    barBorderRadius: [4,4,0,0],
                                }
                            },
                        }
                    ],
                };
                var myChart = this.$echarts.init(chartDom);

                myChart.setOption(option);
            },
            getChartData () {
                let apiUrl
                if(this.infoType == 'business'){
                    apiUrl = this.$Config.apiUrl.getAnalysisDevOfflineBusiness
                }else if(this.infoType == 'income'){
                    apiUrl = this.$Config.apiUrl.getAnalysisDevOfflineIncome
                }else if(this.infoType == 'station'){
                    apiUrl = this.$Config.apiUrl.getAnalysisDevOfflineStation
                }else if(this.infoType == 'province'){
                    apiUrl = this.$Config.apiUrl.getAnalysisDevOfflineCity
                }else if(this.infoType == 'city'){
                    apiUrl = this.$Config.apiUrl.getAnalysisDevOfflineCity
                }else{
                    apiUrl = this.$Config.apiUrl.getStatOfflineDeviceList
                }
                this.$Axios._post({
                    url: apiUrl,
                    method: "post",
                    params: {
                        ...this.params_req,
                        startDay: this.queryStartDate,
                        endDay: this.queryEndDate
                    },
                    showLoading: false,
                }).then(res => {
                    if(res.result.code == 0){
                        let xAxis = []
                        let datas = []
                        let result = res.result.data
                        xAxis = this.defaultXAxis
                        datas = [result.off_1_day,
                                result.off_2_day,
                                result.off_3_day,
                                result.off_4_day,
                                result.off_5_day,
                                result.off_10_day,
                                result.off_15_day,
                                result.off_20_day,
                                result.off_30_day]
                        this.drawChart(xAxis,datas)
                    }else{
                        this.$message.error(res.result.message)
                        this.drawChart(this.defaultXAxis,this.defaultDatas)
                    }
                }).catch(err=>{
                    this.drawChart(this.defaultXAxis,this.defaultDatas)
                    console.log(err);
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.tips{
    width: 11px;
    height: 11px;
    background: #6699FF;
    border-radius: 3px;
}
.mychart{
    width: 100%;
    height: 300px;
}
</style>