<!-- 交易额趋势 -->
<template>
    <div class="a-mt-60">
        <div class="a-flex-rsbc">
            <div class="a-flex-rfsfe">
                <span class="a-c-master a-fw-700 a-fs-16">交易额趋势</span>
                <span class="a-c-normal a-fs-14 a-ml-10">(单位: 元)</span>
            </div>
            <le-date-range 
                @change="dateChange"
                ref="dateRange"
                :pickerOptions="pickerOptions" 
                :minDate.sync="queryStartDate" 
                :maxDate.sync="queryEndDate" 
                :defaultTime="['00:00:00','23:59:59']"
                valueFormat="yyyy-MM-dd HH:mm:ss" />
        </div>
        <div id="mychartTransition" class="mychart"></div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                params_req: '',
                queryStartDate: '',
                queryEndDate: '',
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) {
                            this.choiceDate = ''
                        }
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            let one = 31 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
            }
        },
        mounted () {
            
        },
        props: ['id','params','infoType'],
        watch:{
            params:{
                handler (val) {
                    this.params_req = JSON.parse(JSON.stringify(val))
                    if(this.infoType == 'province' || this.infoType == 'city'){
                        this.queryStartDate = this.$getDay.getTodayBeforeDays(31) + ' 00:00:00'
                        this.queryEndDate = this.$getDay.getTodayBeforeDays(1) + ' 23:59:59'
                    }else{
                        this.queryStartDate = this.$getDay.getTodayBeforeDays(30) + ' 00:00:00'
                        this.queryEndDate = this.$getDay.getTodayBeforeDays(0) + ' 23:59:59'
                    }
                    this.$refs['dateRange'].value1 = [this.queryStartDate, this.queryEndDate]
                    this.getChartData()
                }
            },
        },
        methods: {
            dateChange () {
                this.getChartData()
            },
            drawChart (xAxis=[],data1=[],data2=[],data3=[],data4=[],data5=[],data6=[]) {
                var chartDom = document.getElementById('mychartTransition');
                var option;

                option = {
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '36px',
                        containLabel: true
                    },
                    legend: {
                        itemWidth: 8,
                        itemHeight: 8,
                        right: "2%",
                        itemGap: 40
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        axisLine: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        splitLine:{
                            show:false
                        },
                        axisLabel:{
                            color: '#999999',
                            showMaxLabel: true,
                            formatter: function (value, index) {
                                return value.replace('h','小时')
                            }
                        },
                        data: xAxis
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine:{
                            lineStyle:{
                                color: '#EEEEEE',
                                type: 'dashed'
                            }
                        },
                    },
                    series: [
                        {
                            name: '套餐订单数',
                            data: data4,
                            type: 'bar',
                            barWidth: 10,
                            itemStyle: {
                                normal:{
                                    color: '#6699FF',
                                    barBorderRadius:[2,2,0,0],
                                }
                            },
                        },
                        {
                            name: '套餐交易额',
                            data: data2,
                            type: 'line',
                            itemStyle: {
                                normal:{
                                    color: '#FFA900',
                                }
                            },
                        },
                        {
                            name: '充电桩订单数',
                            data: data3,
                            type: 'bar',
                            barWidth: 10,
                            itemStyle: {
                                normal:{
                                    color: '#29CC29',
                                    barBorderRadius:[2,2,0,0],
                                }
                            },
                        },
                        {
                            name: '充电桩交易额',
                            data: data1,
                            type: 'line',
                            itemStyle: {
                                normal:{
                                    color: '#24D4D0',
                                }
                            },
                        },
                        {
                            name: '充电柜订单数',
                            data: data5,
                            type: 'bar',
                            barWidth: 10,
                            itemStyle: {
                                normal:{
                                    color: '#FFD700',
                                    barBorderRadius:[2,2,0,0],
                                }
                            },
                        },
                        {
                            name: '充电柜交易额',
                            data: data6,
                            type: 'line',
                            itemStyle: {
                                normal:{
                                    color: '#3ba272',
                                }
                            },
                        }
                    ],
                };
                var myChart = this.$echarts.init(chartDom);
                
                myChart.setOption(option,true);
            },
            getChartData () {
                let apiUrl
                if(this.infoType == 'business'){
                    apiUrl = this.$Config.apiUrl.getAnalysisTransitionTrendBusiness
                }else if(this.infoType == 'income'){
                    apiUrl = this.$Config.apiUrl.getAnalysisTransitionTrendIncome
                }else if(this.infoType == 'station'){
                    apiUrl = this.$Config.apiUrl.getAnalysisTransitionTrendStation
                }else if(this.infoType == 'province'){
                    apiUrl = this.$Config.apiUrl.getAnalysisTransitionTrendCity
                }else if(this.infoType == 'city'){
                    apiUrl = this.$Config.apiUrl.getAnalysisTransitionTrendCity
                }
                this.$Axios._post({
                    url: apiUrl,
                    method: "post",
                    showLoading: false,
                    params: {
                        ...this.params_req,
                        startDay: this.queryStartDate,
                        endDay: this.queryEndDate
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        let data1 = [], data2 = [], data3 = [], data4 = [], data5 = [], data6 = [], xAxis = []
                        let dateArr
                        if(res.result.data.length){
                            dateArr = res.result.data
                        }else{
                            dateArr = this.$getDay.enumerateDaysBetweenDates(this.queryStartDate,this.queryEndDate).map(item=>{
                                return {
                                    day: item,
                                    chargingAmount: 0,
                                    chargingNum: 0,
                                    monthCardAmount: 0,
                                    monthCardNum: 0
                                }
                            })
                        }
                        dateArr.map(item=>{
                            xAxis.push(item.day)
                            data1.push(Number((item.chargingAmount/100).toFixed(2)))
                            data2.push(Number((item.monthCardAmount/100).toFixed(2)))
                            data3.push(item.chargingNum|| 0)
                            data4.push(item.monthCardNum || 0)
                            data5.push(item.boxNum || 0)
                            data6.push(Number((item.boxAmount/100).toFixed(2)))
                        })
                        this.drawChart(xAxis,data1,data2,data3,data4,data5,data6)
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.tips{
    width: 11px;
    height: 11px;
    border-radius: 3px;
    margin-left: 30px;
}
.mychart{
    width: 100%;
    height: 300px;
}
</style>