<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main analysisContent" id="analysisContent" ref="analysisContent">
            <span class="a-fw-700 a-c-master title">{{ title }}</span>
            <div class="line"></div>
            <!-- 总收益额分布 -->
            <index-yye-pie 
                :queryUrlIncome="$Config.apiUrl.getIncomeStationMonthCard"
                :queryUrlTransition="$Config.apiUrl.getTransitionStationMonthCard"
                class="a-mt-36" 
                :id="id" 
                :params="params"
                :infoType="infoType"></index-yye-pie>

            <div v-if="stationType == 0">
                <park-order-income :id="id" :params="params" :infoType="infoType"></park-order-income>
                <car-trend :id="id" :params="params" :infoType="infoType"></car-trend>
            </div>

            <EarningsTrend :id="id" :params="params" :infoType="infoType"></EarningsTrend>

            <RevenueStatistics :id="id" :params="params" :infoType="infoType"></RevenueStatistics>

            <points-trends :id="id" :params="params" :infoType="infoType" v-if="(infoType == 'station' || infoType == 'income') && showPermission.showPoints"></points-trends>

            <business-income v-if="infoType=='business'&&$_has(20)" :params="params" :infoType="infoType"></business-income>

            <income-station v-if="infoType=='income'&&$_has(21)" :params="params" :infoType="infoType"></income-station>

            <transition-trend :id="id" :params="params" :infoType="infoType"></transition-trend>
            
            <ChargingDistribution :id="id" :params="params" :infoType="infoType"></ChargingDistribution>
            
            <StationDevSituation v-if="infoType=='station'" :id="id" :params="params" :infoType="infoType"></StationDevSituation><!-- 站点设备情况 -->
            
            <DevOffline :id="id" :params="params" :infoType="infoType"></DevOffline><!-- 离线设备 -->

            <!-- <Electricity v-if="infoType=='station'" :params="params"></Electricity> -->

            <StationBaseinfo v-if="infoType=='station'" :params="params" :infoType="infoType" :stationType.sync="stationType"></StationBaseinfo>
        </el-card>
    </div>
</template>

<script>
import EarningsTrend from './info-child/earningsTrend.vue';
import RevenueStatistics from './info-child/revenueStatistics.vue';
import IncomeDistribution from './info-child/incomeDistribution.vue';
import IncomeDistributionStation from './info-child/incomeDistributionStation.vue';
import ChargingDistribution from './info-child/chargingDistribution.vue';
import DevSituation from './info-child/devSituation.vue';
import DevOffline from './info-child/devOffline.vue';
import DevInstall from './info-child/devInstall.vue';
import Electricity from './info-child/electricity.vue';
import StationBaseinfo from './info-child/station-baseinfo.vue';
import StationDevSituation from './info-child/stationDevSituation.vue';
import RevenueStatisticsStation from './info-child/revenueStatisticsStation.vue';
import IndexYyePie from '../home/child/index-yye-pie.vue'
import ParkOrderIncome from './info-child/parkOrderIncome.vue';
import CarTrend from './info-child/carTrend.vue';
import TransitionTrend from './info-child/transitionTrend.vue';
import businessIncome from './info-child/business-income.vue';
import IncomeStation from './info-child/income-station.vue';
import pointsTrends from './info-child/pointsTrends.vue';
export default {
    components: {
        EarningsTrend,
        RevenueStatistics,
        IncomeDistribution,
        IncomeDistributionStation,
        ChargingDistribution,
        DevSituation,
        DevOffline,
        DevInstall,
        Electricity,
        StationBaseinfo,
        StationDevSituation,
        RevenueStatisticsStation,
        IndexYyePie,
        ParkOrderIncome,
        CarTrend,
        TransitionTrend,
        businessIncome,
        IncomeStation,
        pointsTrends
    },
    data () {
        return {
            infoType: '',
            id: '',//详情查询id
            title: '',//页面头部
            params: {},// 图表详情请求参数
            stationType: -1, // 站点类型，0--车库站点，1--充电站点， 2--充电柜站点，3--水机站点
            stationStartTime: '',
            stationEndTime: '',
            provinceCode: '',
            companyType: '',
            showPermission: {
                showPoints: 0,
                showCabin: 0,
                showBox: 0
            }
        }
    },
    mounted () {
        this.infoType = this.$route.query.type
        this.title = this.$route.query.title
        this.id = this.$route.query.id
        this.stationStartTime = this.$route.query.stationStartTime
        this.stationEndTime = this.$route.query.stationEndTime
        this.provinceCode = this.$route.query.provinceCode
        this.companyType = this.$route.query.companyType

        this.$eventBus.$on('businessToIncomeInfo', (res) => {
            this.infoType = res.type
            this.title = res.title
            this.id = res.id
            this.setParams()
        })
        this.$eventBus.$on('incomeToStationInfo', (res) => {
            this.infoType = res.type
            this.title = res.title
            this.id = res.id
            this.setParams()
        })
        this.setParams()
    },
    methods:{
        setParams () {
            switch (this.infoType) {
                case 'province':
                    this.params = {
                        provinceCode: this.id,
                        stationStartTime: this.stationStartTime,
                        stationEndTime: this.stationEndTime,
                        companyType: this.companyType,
                        searchProvinceType: 1,
                    }
                    this.stationType = 0
                    break;
                case 'income':
                    this.params = {
                        incomeId: this.id,
                    }
                    this.stationType = 0
                    break;
                case 'city':
                    this.params = {
                        cityCode: this.id,
                        provinceCode: this.provinceCode,
                        stationStartTime: this.stationStartTime,
                        stationEndTime: this.stationEndTime,
                        companyType: this.companyType,
                        searchProvinceType: 2,
                    }
                    this.stationType = 0
                    break;
                case 'business':
                    this.params = {
                        companyId: this.id,
                    }
                    this.stationType = 0
                    break;
                case 'station':
                    this.params = {
                        stationId: this.id,
                    }
                    break;
                default:
                    break;
            }
            this.getStatOrderPermission()
        },
        getStatOrderPermission () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getStatOrderPermission,
                method: "post",
                showLoading: false,
                params: {
                    ...this.params,
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.showPermission = {
                        showPoints: res.result.data.points || 0,
                        showCabin: res.result.data.cabin || 0,
                        showBox: res.result.data.box || 0,
                    }
                }else{
                    this.$message.error(res.result.message)
                }
            })
        },
    },
    destroyed() {
        this.$eventBus.$off('businessToIncomeInfo')
        this.$eventBus.$off('incomeToStationInfo')
    }
}
</script>

<style lang="scss" scoped>
.title{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
}
.line{
    width: 100%;
    height: 1px;
    border: 1px solid #EBF0F5;
    margin-top: 21px
}
</style>