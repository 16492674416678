<!-- 电费支出 -->
<template>
    <div class="a-mt-60">
        <div class="a-flex-rsbc a-pt-25">
            <div class="a-flex-rfsfe">
                <span class="a-c-master a-fw-700 a-fs-16">电费支出</span>
                <span class="a-c-normal a-fs-14 a-ml-10">(单位: 元)</span>
            </div>
        </div>
        <div class="a-mt-32 border-box-dev a-flex-rfsc a-flex-wrap">
            <div class="a-flex-cfsfs" style="margin-right: 200px;">
                <span class="a-fs-14 a-c-normal">总电费支出</span>
                <div class="a-flex-rfsb a-mt-10">
                    <span class="a-fs-24 a-c-master">￥</span>
                    <span class="a-fs-24 a-c-master a-fw-700">{{ electricInfo?util.numFormat(electricInfo.electricAmount):'-' }}</span>
                </div>
            </div>

            <div class="a-flex-cfsfs" style="margin-right: 200px;">
                <span class="a-fs-14 a-c-normal">单笔订单平均电费</span>
                <div class="a-flex-rfsb a-mt-10">
                    <span class="a-fs-24 a-c-master">￥</span>
                    <span class="a-fs-24 a-c-master a-fw-700">
                        {{ util.formatMoney(electricInfo.electricAmount/stationHis.hisOrderNum) }}
                    </span>
                </div>
            </div>

            <div class="a-flex-cfsfs">
                <span class="a-fs-14 a-c-normal">订单电费占比</span>
                <div class="a-flex-rfsb a-mt-10">
                    <span class="a-fs-24 a-c-master a-fw-700">{{ electricInfo?electricInfo.electricPercent+'%':'-' }}</span>
                </div>
            </div>
        </div>
        <div class="a-flex-rfsc a-fs-14 a-mt-32">
            <span class="e-title a-c-normal">电费支付方</span>
            <span class="e-content a-c-master">{{ electricInfo&&electricInfo.stationElectricCompanyName?electricInfo.stationElectricCompanyName:'-' }}</span>
        </div>
        <div class="a-flex-rfsc a-fs-14 a-mt-24">
            <span class="e-title a-c-normal">电费支付时间</span>
            <span class="e-content a-c-master">{{ electricInfo&&electricInfo.lastPayElectricDate?electricInfo.lastPayElectricDate:'-' }}</span>
        </div>
        <!-- <div class="a-flex-rfsc a-fs-14 a-mt-24">
            <span class="e-title a-c-normal">电费支付记录</span>
            <span class="e-content a-c-blue" style="cursor: pointer;">查看</span>
            <span class="a-plr-7 a-c-second">&gt;</span>
        </div> -->
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                params_req: '',
                electricInfo: '',// 电费信息
                stationHis: '',
            }
        },
        props:['params'],
        watch:{
            params:{
                immediate:true,
                handler (val) {
                    this.params_req = JSON.parse(JSON.stringify(val))
                    this.getStationAnalysisDetail()
                }
            },
        },
        methods:{
            getStationAnalysisDetail () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getStationAnalysisDetail,
                    method: "get",
                    showLoading: false,
                    params: this.params_req
                }).then(res => {
                    if(res.result.code == 0){
                        this.electricInfo = res.result.data.electricDetailInfo
                        this.stationHis = res.result.data.stationHis
                        
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.border-box-dev{
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #E3E3E3;
    padding: 32px 43px;
}
.e-title{
    width: 100px;
    padding-right: 16px;
}
</style>