<template>
    <div class="indexbox">
        <div class="a-flex-rfec">
            <le-date-range 
                class="timePicker"
                ref="dateRange" 
                :pickerOptions="pickerOptions" 
                :minDate.sync="startTime" 
                :maxDate.sync="endTime" 
                :defaultTime="['00:00:00','23:59:59']"
                valueFormat="yyyy-MM-dd HH:mm:ss" />
        </div>
        <div class="a-flex-rfsc">
            <div class="a-flex-rcc a-flex-1">
                <div ref="incomeChart" style="height:280px;" class="a-flex-1"></div>
            </div>
            <div class="a-flex-rcc a-flex-1">
                <div ref="trendChart" style="height:280px;" class="a-flex-1"></div>
            </div>
        </div>
    </div>
</template>
<script>
import util from '../../../utils/util.js'
export default {
    data() {
        return {
            choiceDate: '',
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    if (maxDate) {
                        this.choiceDate = ''
                    }
                },
                disabledDate: (time) => {
                    if (this.choiceDate) {
                        let one = 31 * 24 * 3600 * 1000
                        const minTime = this.choiceDate - one
                        const maxTime = this.choiceDate + one
                        return time.getTime() < minTime || time.getTime() > maxTime
                    }else{
                        return ''
                    }
                },
            },
            startTime: '',
            endTime: '',
            incomeTotal: 0,
            AmountTotal: 0,
            data: [
                { value: 0, name: '其他', showVal: 0 }
            ],
            incomePie: null,
            transitionPie: null,
            incomePieType: 1, // 收益额展示数据类型
            transitionPieType: 1, // 交易额展示数据类型
            currentTapDom: '',//当前点击图表对象
        }
    },
    props: ['id','params','queryUrlIncome','queryUrlTransition','infoType'],
    mounted() {
            
    },
    computed:{
        dateStr () {
            return this.startTime + this.endTime
        }
    },
    watch:{
        dateStr () {
            this.currentTapDom = ''
            if(this.incomePieType == 1){
                this.orderTypeDistri()
            }else {
                this.getPackageDistri()
            }
        },
        params:{
            handler (val) {
                this.$nextTick(()=>{
                    this.currentTapDom = ''
                    if(this.infoType == 'province' || this.infoType == 'city'){
                        this.startTime = this.$getDay.getTodayBeforeDays(1) + ' 00:00:00'
                        this.endTime = this.$getDay.getTodayBeforeDays(1) + ' 23:59:59'
                    }else{
                        this.startTime = this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                        this.endTime = this.$getDay.getTodayBeforeDays(0) + ' 23:59:59'
                    }
                    this.$refs['dateRange'].value1 = [this.startTime, this.endTime]
                    this.orderTypeDistri()
                })
            }
        },
    },
    methods: {
        // 获取订单类型分布（收益、交易、交易退款）
        orderTypeDistri () {
            let apiUrl
            if(this.infoType == 'business'){
                apiUrl = this.$Config.apiUrl.getAnalysisOrderTypeBusiness
            }else if(this.infoType == 'income'){
                apiUrl = this.$Config.apiUrl.getAnalysisOrderTypeIncome
            }else if(this.infoType == 'station'){
                apiUrl = this.$Config.apiUrl.getAnalysisOrderTypeStation
            }else if(this.infoType == 'province'){
                apiUrl = this.$Config.apiUrl.getAnalysisOrderTypeCity
            }else if(this.infoType == 'city'){
                apiUrl = this.$Config.apiUrl.getAnalysisOrderTypeCity
            }
            this.$Axios._post({
                url: apiUrl,
                showLoading: false,
                params: {
                    ...this.params,
                    endDay: this.endTime,
                    startDay: this.startTime
                }
            }).then((res) => {
                if (res.result.code == 0) {
                    let incomeData = [], transitionData = [], transitionRefound = []
                    let allIncome = 0, allTransition = 0, allRefound = 0
                    this.incomeTotal = 0
                    res.result.data.map(item=>{
                        // 收益
                        incomeData.push({
                            name: item.name,
                            showVal: item.incomeAmount 
                                    ? Number((item.incomeAmount/100).toFixed(2))
                                    : 0,
                            value: item.incomeAmount 
                                    ? item.incomeAmount >= 0
                                        ? Number((item.incomeAmount/100).toFixed(2)) 
                                        : 0
                                    : 0
                        })
                        allIncome += (item.incomeAmount 
                                    ? Number((item.incomeAmount/100).toFixed(2))
                                    : 0)
                        this.incomeTotal += item.incomeAmount 
                                    ? item.incomeAmount >= 0
                                        ? Number((item.incomeAmount/100).toFixed(2)) 
                                        : 0
                                    : 0

                        // 交易额
                        transitionData.push({
                            name: item.name,
                            showVal: item.transactionAmount 
                                    ? Number((item.transactionAmount/100).toFixed(2))
                                    : 0,
                            value: item.transactionAmount 
                                    ? item.transactionAmount >= 0
                                        ? Number((item.transactionAmount/100).toFixed(2)) 
                                        : 0
                                    : 0
                        })
                        allTransition += (item.transactionAmount 
                                    ? Number((item.transactionAmount/100).toFixed(2))
                                    : 0)

                        // 退款
                        transitionRefound.push({
                            name: item.name,
                            showVal: item.refundAmount 
                                    ? Number((item.refundAmount/100).toFixed(2))
                                    : 0,
                            value: item.refundAmount 
                                    ? item.refundAmount >= 0
                                        ? Number((item.refundAmount/100).toFixed(2)) 
                                        : 0
                                    : 0
                        })
                        allRefound += (item.refundAmount 
                                    ? Number((item.refundAmount/100).toFixed(2))
                                    : 0)
                    })
                    if(this.currentTapDom == 'income' || !this.currentTapDom){
                        this.drawChart( incomeData&&incomeData.length?incomeData:this.data,
                                    this.$refs['incomeChart'],
                                    '订单总收益 ￥' + Number(allIncome.toFixed(2)),
                                    Number(this.incomeTotal.toFixed(2)))
                    }
                    
                    if(this.currentTapDom == 'transition' || !this.currentTapDom){
                        this.drawChartTransition( [transitionData&&transitionData.length?transitionData:this.data,transitionRefound&&transitionRefound.length?transitionRefound:this.data],
                                    this.$refs['trendChart'],
                                    '订单总交易额 ￥' + Number(allTransition.toFixed(2)) + '\n订单总退款 ￥' + Number(allRefound.toFixed(2)),
                                    Number(allTransition.toFixed(2)),Number(allRefound.toFixed(2)))   
                    }

                    
                } else {
                    this.$message.error(res.result.msg)
                }

            }).catch(error => {
                this.$message.error(error)
            })
        },
        // 获取车库套餐交易分布
        getPackageDistri () {
            let apiUrl
            if(this.infoType == 'business'){
                apiUrl = this.$Config.apiUrl.getAnalysisMonthCardTypeBusiness
            }else if(this.infoType == 'income'){
                apiUrl = this.$Config.apiUrl.getAnalysisMonthCardTypeIncome
            }else if(this.infoType == 'station'){
                apiUrl = this.$Config.apiUrl.getAnalysisMonthCardTypeStation
            }
            this.$Axios._post({
                url: apiUrl,
                showLoading: false,
                params: {
                    ...this.params,
                    endDay: this.endTime,
                    startDay: this.startTime
                }
            }).then(res=>{
                let incomeData = [], transitionData = [], transitionRefound = []
                    let allIncome = 0, allTransition = 0, allRefound = 0
                    this.incomeTotal = 0
                    res.result.data.map(item=>{
                        // 收益
                        incomeData.push({
                            name: item.name,
                            showVal: item.incomeAmount 
                                    ? Number((item.incomeAmount/100).toFixed(2))
                                    : 0,
                            value: item.incomeAmount 
                                    ? item.incomeAmount >= 0
                                        ? Number((item.incomeAmount/100).toFixed(2)) 
                                        : 0
                                    : 0
                        })
                        allIncome += (item.incomeAmount 
                                    ? Number((item.incomeAmount/100).toFixed(2))
                                    : 0)
                        this.incomeTotal += item.incomeAmount 
                                    ? item.incomeAmount >= 0
                                        ? Number((item.incomeAmount/100).toFixed(2)) 
                                        : 0
                                    : 0

                        // 交易额
                        transitionData.push({
                            name: item.name,
                            showVal: item.transactionAmount 
                                    ? Number((item.transactionAmount/100).toFixed(2))
                                    : 0,
                            value: item.transactionAmount 
                                    ? item.transactionAmount >= 0
                                        ? Number((item.transactionAmount/100).toFixed(2)) 
                                        : 0
                                    : 0
                        })
                        allTransition += (item.transactionAmount 
                                    ? Number((item.transactionAmount/100).toFixed(2))
                                    : 0)

                        // 退款
                        transitionRefound.push({
                            name: item.name,
                            showVal: item.refundAmount 
                                    ? Number((item.refundAmount/100).toFixed(2))
                                    : 0,
                            value: item.refundAmount 
                                    ? item.refundAmount >= 0
                                        ? Number((item.refundAmount/100).toFixed(2)) 
                                        : 0
                                    : 0
                        })
                        allRefound += (item.refundAmount 
                                    ? Number((item.refundAmount/100).toFixed(2))
                                    : 0)
                    })
                    if(this.currentTapDom == 'income' || !this.currentTapDom){
                        this.drawChart( incomeData&&incomeData.length?incomeData:this.data,
                                    this.$refs['incomeChart'],
                                    '套餐总收益 ￥' + Number(allIncome.toFixed(2)),
                                    Number(this.incomeTotal.toFixed(2)))
                    }
                    
                    if(this.currentTapDom == 'transition' || !this.currentTapDom){
                        this.drawChartTransition( [transitionData&&transitionData.length?transitionData:this.data,transitionRefound&&transitionRefound.length?transitionRefound:this.data],
                                    this.$refs['trendChart'],
                                    '套餐总交易额 ￥' + Number(allTransition.toFixed(2)) + '\n套餐总退款 ￥' + Number(allRefound.toFixed(2)),
                                    Number(allTransition.toFixed(2)),Number(allRefound.toFixed(2))) 
                    }
                      
            }).catch(err=>{
                this.$message.error(err)
            })
        },
        drawChart(data = [], chartDom= null, title= '', totalNum = 0) {
            var option;
            option = {
                title: {
                    text: title,
                    left: 'center',
                    top: 'bottom',
                    textStyle: {
                        fontWeight: 'normal',
                        fontSize: 14
                    }
                },
                color: ['#3BA1FF', '#4DCB73', '#F56C6C', '#FF9C00', '#FFD700','#3ba272'],
                tooltip: {
                    trigger: 'item',
                    formatter: (item) =>{
                        let htmlContxt = `<div class="a-flex-cfsfs" style='background:#FFFFFF;min-width:100px'>
                                <span style='font-size: 14px;color: #606366;'>`+ item.data.name +`</span>`
                            +`<span style='font-size: 17px;font-weight: 700;color: #303133;padding:10px 0 0'>`+ item.data.showVal +`</span>`
                            return htmlContxt
                    }
                },
                series: [
                    {
                        name: '收益分布',
                        type: 'pie',
                        radius: ['0', '65%'],
                        center: ['50%', '45%'],
                        itemStyle: {
                            borderRadius: 4,
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        label: {
                            normal: {
                                formatter: (params)=> {
                                    return params.data.name + ' ' + params.data.showVal + ' （' + (totalNum?(params.data.value/totalNum*100).toFixed(2):0) + '%）'
                                }
                            }
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '12',
                                fontWeight: 'bold'
                            }
                        },
                        data: data.map(item => item = { ...item, 'value': item.value })
                    },
                ]
            }
            if(!this.incomePie){
                this.incomePie = this.$echarts.init(chartDom)
                this.incomePie.on('click',(params)=>{
                    this.currentTapDom = 'income'
                    if(params.name == '套餐订单' && this.incomePieType == 1){
                        this.incomePieType = 2
                        this.getPackageDistri() // 获取车库收益额详情分布（临停、停车充电、停车）
                    }else if(this.incomePieType == 2){
                        this.incomePieType = 1
                        this.orderTypeDistri()
                    }
                })
            }
            this.incomePie.clear()
            this.incomePie.setOption(option, true)

            window.addEventListener("resize", () => {
                if (this.incomePie) {
                    this.incomePie.resize()
                }
            })
        },
        drawChartTransition(data = [], chartDom= null, title= '', totalNum = 0, allRefound = 0) {
            var option;
            option = {
                title: {
                    text: title,
                    left: 'center',
                    top: 'bottom',
                    textStyle: {
                        fontWeight: 'normal',
                        fontSize: 14
                    }
                },
                color: ['#3BA1FF', '#4DCB73', '#F56C6C', '#FF9C00', '#FFD700','#3ba272'],
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        name: '交易额分布',
                        type: 'pie',
                        radius: ['0', '40%'],
                        center: ['50%', '45%'],
                        itemStyle: {
                            borderRadius: 4,
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        label: {
                            normal: {
                                formatter: (params)=> {
                                    return params.data.name + ' ' + params.data.value + ' （' + (totalNum?(params.data.value/totalNum*100).toFixed(2):0) + '%）'
                                },
                                
                            }
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '12',
                                fontWeight: 'bold'
                            }
                        },
                        data: data[0].map(item => item = { ...item, 'value': item.value })
                    },
                    {
                        name: '交易额分布（退款）',
                        type: 'pie',
                        radius: ['50%', '65%'],
                        center: ['50%', '45%'],
                        itemStyle: {
                            borderRadius: 2,
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        label: {
                            normal: {
                                formatter: (params)=> {
                                    return params.data.name + '(退款) ' + params.data.value + ' （' + (allRefound?(params.data.value/allRefound*100).toFixed(2):0) + '%）'
                                }
                            }
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '12',
                                fontWeight: 'bold'
                            }
                        },
                        data: data[1].map(item => item = { ...item, 'value': item.value })
                    }
                ]
            }
            if(!this.transitionPie){
                this.transitionPie = this.$echarts.init(chartDom)
                this.transitionPie.on('click',(params)=>{
                    this.currentTapDom = 'transition'
                    if(params.name == '套餐订单' && this.transitionPieType == 1){
                        this.transitionPieType = 2
                        this.getPackageDistri()
                    }else if(this.transitionPieType == 2){
                        this.transitionPieType = 1
                        this.orderTypeDistri()
                    }
                })
            }
            this.transitionPie.clear()
            this.transitionPie.setOption(option, true)

            

            window.addEventListener("resize", () => {
                if (this.transitionPie) {
                    this.transitionPie.resize()
                }
            })
        },
    },
    
}
</script>
<style lang="scss" scoped>
    .timePicker{
        padding: 0 !important;
    }
</style>